<!-- 用户反馈 -->
<template>
  <div class="feedback center-wrap">
    <div class="page">
      <h1 v-text="$t('fb.title')">填写反馈内容</h1>
      <div class="text-box">
        <p v-text="$t('fb.input_title')">输入反馈内容</p>
        <div>
          <textarea :placeholder="$t('fb.input_size')" v-model="content" maxlength="200"></textarea>
        </div>
      </div>
      <p class="photo-title" v-text="$t('fb.upload')">上传图片</p>
      <div class="photo-box">
        <div class="book" v-for="(item,index) in fileList" :key="index">
          <img :src="item" />
          <p class="click" v-text="$t('fb.del')" @click.stop="fileList.splice(index,1)">删除</p>
        </div>
        <!-- 添加图片 -->
        <div class="add click" v-if="fileList.length < 5">
          <img src="../assets/icon/add.png" />
          <input type="file" accept="image/*" @change="handleFileChange" ref="uploadFile" />
        </div>
      </div>
      <div class="contact-box">
        <p v-text="$t('fb.contact')">联系方式</p>
        <input type="text" v-model="mobile" maxlength="30" />
      </div>
      <div class="submit click" v-text="$t('fb.submit')" @click.stop="fnSubmit">提交</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Feedback',
  data() {
    return {
      fileList: [],
      mobile: null,
      content: null,
    }
  },
  methods: {
    ischeckemail: function (email) {
      if (email != '') {
        var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
        var isok = reg.test(email)
        if (!isok) {
          return false
        } else return true
      }
    },
    isTel: function (phone) {
      if (!/^\d{11,15}$/.test(phone)) {
        return false
      } else return true
    },
    fnSubmit: function () {
      var self = this
      if (!self.content)
        return self.common.showMessage(self.$t('fb.body'), 'error') //请输入内容
      // if (self.fileList.length == 0) return self.common.showMessage(self.$t('fb.photo'), 'error');//请上传图片
      // if (!self.mobile) return self.common.showMessage(self.$t('fb.num'), 'error');//请输入联系方式

      if (self.mobile) {
        if (!self.ischeckemail(self.mobile) && !self.isTel(self.mobile)) {
          return self.common.showMessage(self.$t('fb.mobile_err'), 'error') //请输入内容
        }
      }

      self.common
        .httpPost('/api/user/createFeedBack', {
          contact_mobile: self.mobile ? self.mobile : '',
          content: self.content,
          images: self.fileList.length ? self.fileList.toString() : '',
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            self.fileList = []
            self.content = null
            self.mobile = null
          }
        })
    },
    handleFileChange() {
      var self = this
      var file = this.$refs.uploadFile.files[0]
      if (!file) return
      var formdata = new FormData()
      formdata.append('file', file, file.name)
      let config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      this.common.showLoading(self.$t('fb.photo_logoing')) //图片上传中...
      axios
        .post('/api/ajax/uploadImage', formdata, config)
        .then((response) => {
          self.common.hideLoading()
          if (response.data.code == 1) {
            self.fileList.push(response.data.url)
          } else self.common.showMessage(self.$t('fb.photo_bad'), 'error') //图片上传失败
        })
        .catch((error) => {
          self.common.hideLoading()
          // console.log(JSON.stringify(error));
          var originalRequest = error.config
          if (
            error.code == 'ECONNABORTED' &&
            error.message.indexOf('timeout') != -1
          ) {
            self.common.showMessage(self.$t('fb.photo_timeout'), 'error') //图片上传失败
          } else {
            self.common.showMessage(self.$t('fb.photo_bad'), 'error') //图片上传失败
          }
        })
      this.$refs.uploadFile.value = null
    },
  },
}
</script>
<style>
.el-upload-list--picture-card .el-progress {
  width: 65px !important;
  height: 65px !important;
  display: flex;
}

.el-upload--picture-card {
  width: 110px !important;
  height: 110px !important;
  line-height: 110px !important;
}

.el-upload-list__item-actions {
  width: 110px !important;
  height: 110px !important;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 110px !important;
  height: 110px !important;
}
</style>
<style scoped>
.page {
  margin-left: 283px;
  margin-right: 283px;
}

h1 {
  font-size: 20px;
  color: #999999;
  line-height: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}

.text-box {
  height: 213px;
  width: 800px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  border-radius: 4px;
}

.text-box p {
  padding-left: 30px;
  line-height: 50px;
  font-weight: 500;
  color: rgba(171, 171, 171, 1);
  background: #f7f6f6;
}

.text-box div {
  height: 131px;
  width: 740px;
  padding: 16px 30px;
  border-radius: 4px;
}

textarea {
  height: 131px;
  width: 740px;
  border-radius: 4px;
}

.photo-title {
  font-size: 14px;
  color: #333333;
  line-height: 16px;
  margin-top: 30px;
  margin-bottom: 16px;
}

.photo-box {
  width: 835px;
  display: flex;
  flex-wrap: wrap;
}

.book {
  height: 109px;
  width: 109px;
  position: relative;
  margin-right: 30px;
  margin-bottom: 20px;
}

.book p {
  height: 24px;
  width: 109px;
  font-size: 14px;
  color: #ffffff;
  line-height: 24px;
  background: rgba(153, 153, 153, 0.8);
  text-align: center;
  position: absolute;
  bottom: 0;
}

.book img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  /* height: 109px; */
  /* width: 109px; */
}

.add {
  height: 110px;
  width: 110px;
  border: 1px solid rgba(204, 204, 204, 0.7);
  border-radius: 2px;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}

.add input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  height: 110px !important;
  width: 110px !important;
}

.add img {
  height: 28px;
  width: 28px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.contact-box {
  padding-top: 25px;
  display: flex;
  align-items: center;
}

.contact-box p {
  font-size: 14px;
  color: #333333;
  line-height: 16px;
  margin-right: 20px;
}

.contact-box input {
  height: 34px;
  width: 250px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  border-radius: 4px;
  padding-left: 15px;
}

.submit {
  height: 35px;
  width: 160px;
  margin: 80px auto;
  background: #297fab;
  font-size: 16px;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  border-radius: 4px;
}
</style>